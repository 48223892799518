<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" dark icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-btn :to="{name: 'about'}" text>
          {{ $vuetify.lang.t('$vuetify.general.navItems.about') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn :to="{name: 'contact'}" text>
          {{ $vuetify.lang.t('$vuetify.general.navItems.contactUs') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn :to="{name: 'search'}" text>
          {{ $vuetify.lang.t('$vuetify.general.navItems.sites') }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn :to="{name: 'site'}" text>
          {{ $vuetify.lang.t('$vuetify.general.becomeAVendor') }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'KurccHomePageNavMenu'
}
</script>
